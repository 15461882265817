import React from "react";
import { Card, Typography } from "@material-ui/core";

import { formatTime } from "../util";

interface Props {
  heading: string;
  times: [string, number][];
}

const RaceResult: React.FC<Props> = (props: Props) => {
  return (
    <Card raised>
      <Typography variant="h6">{props.heading}</Typography>
      <div>
        <ol>
          {props.times.map(([username, time]) => (
            <Typography key={username} component="li">
              {username}: {formatTime(time)}
            </Typography>
          ))}
        </ol>
      </div>
    </Card>
  );
};

export default RaceResult;
