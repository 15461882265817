import React from "react";

import { Typography } from "@material-ui/core";

const RaceNotes: React.FC = () => {
  return (
    <div>
      <Typography variant="h6">Notes</Typography>
      <ul>
        <Typography component="li" variant="body2">
          <strong>
            To match the cube net shown, scramble the cube using the given moves
            with white on top and green in front.
          </strong>{" "}
          To learn about the cubing notation used here, see{" "}
          <a href="https://www.youtube.com/watch?v=24eHm4ri8WM">
            this video by J Perm
          </a>{" "}
          or{" "}
          <a href="https://ruwix.com/the-rubiks-cube/notation/">this article</a>
          .
        </Typography>
        <Typography component="li" variant="body2">
          Once all users in a room are ready, there is a 15-second countdown
          (serving as an inspection period) before the race starts. Do not
          inspect the cube before the countdown period.
        </Typography>
        <Typography component="li" variant="body2">
          Stop the timer by pressing any key or by clicking on the window.
        </Typography>
        <Typography component="li" variant="body2">
          A new scramble will be automatically generated after everyone has
          solved their cube.
        </Typography>
      </ul>
    </div>
  );
};

export default RaceNotes;
