import React from "react";
import { Typography } from "@material-ui/core";

const HomePage: React.FC = () => {
  return (
    <div>
      <Typography variant="h3" align="center">
        Home Page
      </Typography>
      <Typography align="center">Welcome to the Cubing Web App!</Typography>
    </div>
  );
};

export default HomePage;
