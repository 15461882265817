/*

*/

import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "../axiosConfig";
import { Button } from "@material-ui/core";

import RaceNotes from "../components/RaceNotes";

const RaceLobbyPage: React.FC = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleCreate = async () => {
    try {
      const res = await axios.post("/create-room");
      const { roomId } = res.data;
      history.push(`${path}/${roomId}`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleJoin = async () => {
    const roomId = prompt("enter room code");
    if (roomId) {
      history.push(`${path}/${roomId}`);
    }
  };

  return (
    <div>
      <Button onClick={handleCreate}>Create new room</Button>
      <Button onClick={handleJoin}>Join existing room</Button>
      <RaceNotes />
    </div>
  );
};

export default RaceLobbyPage;
