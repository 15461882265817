import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from "react-router-dom";
import axios from "./axiosConfig";
import { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";

import { RefreshTokenResponse } from "./common/interfaces";

import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import LogoutPage from "./pages/Logout";
import NotFoundPage from "./pages/NotFound";
import RaceLobbyPage from "./pages/RaceLobby";
import RaceRoomPage from "./pages/RaceRoom";
import RegisterPage from "./pages/Register";
import TrainerPage from "./pages/Trainer";

import Layout from "./layout/Layout";
import { setAccessToken } from "./store/accessToken";
import UserContext from "./store/userContext";

const ProtectedRoute: React.FC<RouteProps> = ({
  children,
  ...rest
}: RouteProps) => {
  const userContext = useContext(UserContext);
  const location = useLocation();

  if (!userContext.username) {
    return <Redirect to={`/login?next=${location.pathname}`} />;
  }

  return <Route {...rest}>{children}</Route>;
};

interface AccessTokenPayload {
  iat: number; // issued at
  exp: number; // expiry time
  username: string;
}

const App: React.FC = () => {
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post("/refresh-token") // includes cookies
      .then(async (res: AxiosResponse<RefreshTokenResponse>) => {
        // TODO: having "ok" is kind of redundant?
        const { ok, accessToken } = res.data;
        if (ok) {
          setAccessToken(accessToken);
          const payload = jwt.decode(accessToken) as AccessTokenPayload;
          userContext.handleLogin(payload.username);
        }
        setLoading(false);
      });
  }, [userContext]);

  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {/*
          this is more efficient than:
          <Route path="/" exact component={HomePage} />
          since the children prop gets re-rendered more cleverly?
          */}
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/login" exact>
            <LoginPage />
          </Route>
          <Route path="/logout" exact>
            <LogoutPage />
          </Route>
          <Route path="/register" exact>
            <RegisterPage />
          </Route>

          {/* Race stuff; TODO: make nesting work? */}
          <ProtectedRoute path="/race" exact>
            <RaceLobbyPage />
          </ProtectedRoute>
          <ProtectedRoute path="/race/:roomId">
            <RaceRoomPage />
          </ProtectedRoute>

          <Route path="/trainer" exact>
            <TrainerPage />
          </Route>

          {/* 404 not found */}
          <Route path="/">
            <NotFoundPage />
          </Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
