import { useEffect, useState } from "react";
import config from "../config";

export function useStopwatch(
  intervalSize = config.TIMER_INTERVAL_SIZE
): readonly [
  number,
  React.Dispatch<React.SetStateAction<number>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] {
  const [elapsed, setElapsed] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        setElapsed((e) => e + intervalSize);
      }, intervalSize);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isRunning, intervalSize]);

  return [elapsed, setElapsed, isRunning, setIsRunning] as const;
}
