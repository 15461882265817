/*
A room where racing occurs.

TODO: handle what happens when the window goes in and out of focus
*/

import React, { useContext, useEffect, useState } from "react";
import assert from "assert";
import axios from "../axiosConfig";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";

import Scramble from "../components/Scramble";

import events from "../common/events";
import {
  JoinRoomRequest,
  RaceData,
  RoomData,
  UserData,
} from "../common/interfaces";

import config from "../config";
import RaceResult from "../components/RaceResult";
import { useStopwatch } from "../hooks/timing";
import RaceNotes from "../components/RaceNotes";
import UserContext from "../store/userContext";
import { formatTime } from "../util";

const RaceRoomPage: React.FC = () => {
  const { username } = useContext(UserContext);

  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();

  // context stuff
  const [roomData, setRoomData] = useState(new RoomData(""));

  // set the version number to force updates
  const [
    [
      users,
      // _version
    ],
    setUsers,
  ] = useState<[Map<string, UserData>, number]>([new Map(), 0]);

  const [myData, setMyData] = useState(new UserData(""));
  const [results, setResults] = useState<RaceData[]>([]);

  const addResult = (data: RaceData) => {
    setResults((cur) => cur.concat(data));
  };

  const [ready, setReady] = useState(false);
  const [showScramble, setShowScramble] = useState(true);

  // timer (countdown, handled on server side) and stopwatch
  const [countdownTime, setCountdownTime] = useState(0);
  const [elapsed, setElapsed, isRunning, setIsRunning] = useStopwatch();

  const [socket, setSocket] = useState<Socket>();

  // initialization
  useEffect(() => {
    const setUserData = (data: UserData, callbackIfNew?: () => void) => {
      setUsers(([users, version]) => {
        if (!users.has(data.username) && callbackIfNew) {
          callbackIfNew();
        }
        users.set(data.username, data);
        return [users, version + 1];
      });
      if (data.username == username) {
        setMyData(data);
      }
    };

    const resetUsers = () => {
      setUsers(([users, version]) => {
        for (const user of users.values()) {
          user.isReady = user.solved = false;
        }
        return [users, version + 1];
      });
      setReady(false);
    };

    // console.log("setting up socket");

    const socket = io(config.BACKEND_SERVER_URL, {
      auth: { username },
      autoConnect: false,
    });

    socket.on(
      events.AllRoomData,
      // socket.io probably uses JSON as the serialization format,
      // so we can't send a Map<string, UserData>.
      (room: RoomData, userArray: [string, UserData][]) => {
        setRoomData(room);
        for (const [, user] of userArray) {
          setUserData(user);
        }
      }
    );

    socket.on(events.RoomData, (data: RoomData) => {
      setRoomData(data);
    });

    socket.on(events.UserData, (user: UserData) => {
      setUserData(user, () => {
        console.log(`new user ${user.username} joined`);
      });
    });

    socket.on(events.UserLeft, (username: string) => {
      setUsers(([users, version]) => {
        users.delete(username);
        return [users, version + 1];
      });
      resetUsers();
    });

    socket.on(events.CountdownStart, () => {
      // countdown starting for stopwatch to start at `startTime`
      // doing this client-side avoids minor time sync discrepancies
      // with the server, which may result in "jumping" by some time
      // when the solve stopwatch is stopped
      setElapsed(0);
      setRoomData((cur) => {
        cur.inCountdown = true;
        return cur;
      });
      setShowScramble(false);
    });

    socket.on(events.CountdownTime, (time: number) => {
      setCountdownTime(time);
      if (time == 0) {
        // TODO: later, allow people to start on their own terms?
        setIsRunning(true);
      }
    });

    socket.on(events.RaceOver, (result: RaceData, newScramble: string) => {
      // TODO: summary screen?
      // alert("Race ended!");
      setReady(false);
      setShowScramble(true);
      addResult(result);
      setRoomData((cur) => {
        cur.scramble = newScramble;
        return cur;
      });
      resetUsers();
    });

    socket.onAny((...args) => {
      if (process.env.NODE_ENV != "production") {
        // for debugging only
        console.log("socket received:", args);
      }
    });

    socket.on("disconnect", (reason) => {
      if (process.env.NODE_ENV != "production") {
        // for debugging only
        console.log("disconnected:", reason);
      }
    });

    socket.on("connect", () => {
      const payload: JoinRoomRequest = {
        username,
        socketId: socket.id,
      };
      axios.post(`/join-room/${roomId}`, payload).catch((err) => {
        alert(`error: ${err.response.data.error}`);
        history.push("/race");
      });
    });

    socket.connect();

    setSocket(socket);

    return () => {
      socket.disconnect();
      // handle leaving on server side through sockets
    };
  }, [username, setElapsed, setIsRunning, history, roomId]);

  useEffect(() => {
    // should this be document or window?
    if (isRunning) {
      const stopStopwatch = () => {
        // check to see if we can use functions to update state to avoid
        // the annoying "key spam" bug where the user's time is the time since
        // January 1, 1970
        // console.log("stopping stopwatch");
        setIsRunning(false);
        assert(socket);
        const solvedAt = Date.now();
        socket.emit(events.UserSolved, solvedAt);
      };

      window.addEventListener("keydown", stopStopwatch);
      window.addEventListener("click", stopStopwatch);
      return () => {
        // runs just before isRunning changes (from true to false)
        window.removeEventListener("keydown", stopStopwatch);
        window.removeEventListener("click", stopStopwatch);
      };
    }
  }, [isRunning, setIsRunning, socket, roomData.startTime]);

  if (!roomData || myData.username == "n/a" || !socket) {
    return <Typography variant="body2">loading...</Typography>;
  }

  const handleReady = () => {
    // TODO: allow readying up with the "R" key?
    setReady(true);
    socket.emit(events.UserReady);
  };

  const getStatusJsx = (user: UserData) => {
    let color: string;
    let text: string;
    if (roomData.inCountdown) {
      color = "black";
      text = "inspecting...";
    } else if (roomData.startTime == -1) {
      if (user.isReady) {
        color = "green";
        text = "ready";
      } else {
        color = "grey";
        text = "not ready";
      }
    } else {
      if (user.solved) {
        color = "blue";
        text = formatTime(user.lastSolveTime - roomData.startTime);
      } else {
        color = "black";
        text = "solving...";
      }
    }
    return <span style={{ color }}>{text}</span>;
  };

  const getUserJsx = (user: UserData) => {
    const isMe = user.username == username;
    return (
      <>
        [{getStatusJsx(user)}]{" "}
        {isMe ? <strong>{user.username} (me)</strong> : <>{user.username}</>}
      </>
    );
  };

  const getTimeString = () => {
    if (!myData.solved) {
      const res = formatTime(elapsed);
      return res.substring(0, res.length - 2);
    }
    return formatTime(myData.lastSolveTime - roomData.startTime);
  };

  // TODO: make the main page non-scroll; only the right "bar" can scroll?
  return (
    <div>
      <Grid
        container
        spacing={4}
        // style={{ height: "100%" }}
      >
        <Grid
          item
          container
          xs
          justify={showScramble ? "flex-start" : "center"}
          // style={{ height: "100%" }}
        >
          {showScramble && (
            <Grid item>
              <Typography variant="h4">Room Code: {roomData.roomId}</Typography>
              <RaceNotes />
              <Scramble scramble={roomData.scramble} />
              <Button
                onClick={handleReady}
                disabled={ready}
                variant="contained"
                color="primary"
              >
                Ready up!
              </Button>
            </Grid>
          )}

          <Grid item>
            {countdownTime > 0 && (
              <Typography variant="h3">Countdown: {countdownTime}</Typography>
            )}
            {/* the stopwatch */}
            {/* TODO: clean up this conditional */}
            {(!showScramble || isRunning) && !roomData.inCountdown && (
              <Typography variant="h3">{getTimeString()}</Typography>
            )}
          </Grid>
        </Grid>

        {/* right sidebar */}
        <Grid item container xs={3} direction="column">
          {/* list of players */}
          <Typography variant="h5">Players</Typography>
          <List
            component="ul"
            style={{
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            {/* TODO: clean up? */}
            {Array.from(users.values()).map((user: UserData) => (
              <ListItem key={user.username} button>
                <ListItemText>{getUserJsx(user)}</ListItemText>
              </ListItem>
            ))}
          </List>

          {/* list of past race results */}
          <Typography variant="h5">Past Races</Typography>
          <Grid item container direction="column" spacing={1}>
            {results.length == 0 && (
              <Grid item>
                <Typography variant="body2">
                  No races yet. Start one now!
                </Typography>
              </Grid>
            )}
            {results
              .map((entry: RaceData, index: number) => (
                <Grid key={index} item>
                  <RaceResult
                    heading={`Race ${index + 1}`}
                    times={entry.times}
                  />
                </Grid>
              ))
              .reverse()}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RaceRoomPage;
