enum EventKind {
  // in rooms
  UserJoined = "user joined room",
  UserLeft = "user left room",
  UserReady = "user ready",
  CountdownStart = "countdown started",
  CountdownTime = "countdown timer",
  UserSolved = "user solved",
  RaceOver = "race over",

  // data
  UserData = "user data",
  RoomData = "room data",
  AllRoomData = "all room data",
}

export default EventKind;
