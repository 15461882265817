export class UserData {
  public roomId = "";
  public isReady = false;
  public solved = false;
  public lastSolveTime = 0;

  constructor(public username: string) {}

  static toRedisObject(data: UserData): { [key: string]: string } {
    return {
      username: data.username,
      roomId: data.roomId,
      isReady: data.isReady ? "1" : "0",
      solved: data.solved ? "1" : "0",
      lastSolveTime: data.lastSolveTime.toString(),
    };
  }

  static fromRedisObject(data: { [key: string]: string }): UserData {
    return {
      username: data.username,
      roomId: data.roomId,
      isReady: data.isReady == "1",
      solved: data.solved == "1",
      lastSolveTime: parseInt(data.lastSolveTime),
    };
  }
}

export class RoomData {
  public scramble = "";
  public startTime = -1;
  public numResults = 0;
  public inCountdown = false;

  constructor(public roomId: string) {}
}

// make this more "relational" with foreign keys?

export interface RaceData {
  roomId: string;
  times: [string, number][]; // username, time
}

export interface RefreshTokenResponse {
  ok: boolean;
  accessToken: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface JoinRoomRequest {
  username: string;
  socketId: string;
}
