import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

import { getImageBase64 } from "./scramble-image";

interface Props {
  scramble: string;
}

const Scramble: React.FC<Props> = ({ scramble }: Props) => {
  const [imageSource, setImageSource] = useState("");

  useEffect(() => {
    if (scramble.length > 0) {
      getImageBase64(scramble).then((data: string) => {
        setImageSource(data);
      });
    }
  }, [scramble]);

  if (imageSource == "") {
    return <Typography variant="body2">loading scramble...</Typography>;
  }

  return (
    // generate the image string on the server?
    <div>
      <Typography variant="body1">
        <strong>Scramble: </strong>
        {scramble}
      </Typography>
      {<img src={imageSource} alt="scramble net" />}
    </div>
  );
};

export default Scramble;
